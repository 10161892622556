import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import Layout from "@components/Layout";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  description,
  imgAdministrativo,
  imgCivil,
  imgConstitucional,
  imgFamilia,
  imgJudicial,
  imgLaboral,
  imgPenal,
  imgTransito,
  logoWhatsapp,
  logoFace,
}) => (
  <div>
    <BackgroundImage
      Tag="section"
      fluid={image.childImageSharp.fluid}
      fadeIn="soft"
    >
      <div className="hero">
        <h1>{title}</h1>
      </div>
    </BackgroundImage>
    <section>
      <div className="set">
        <ul className="info">
          <li>
            <Img className="image"
              alt="Decorative Image"
              fluid={imgAdministrativo.childImageSharp.fluid}
            />
          </li>
          <li>
            <Img className="image"
              alt="Decorative Image"
              fluid={imgCivil.childImageSharp.fluid}
            />
          </li>
          <li>
            <Img className="image"
              alt="Decorative Image"
              fluid={imgConstitucional.childImageSharp.fluid}
            />
          </li>
          <li>
            <Img className="image"
              alt="Decorative Image"
              fluid={imgFamilia.childImageSharp.fluid}
            />
          </li>
          <li>
            <Img className="image"
              alt="Decorative Image"
              fluid={imgJudicial.childImageSharp.fluid}
            />
          </li>
          <li>
            <Img className="image"
              alt="Decorative Image"
              fluid={imgLaboral.childImageSharp.fluid}
            />
          </li>
          <li>
            <Img className="image"
              alt="Decorative Image"
              fluid={imgPenal.childImageSharp.fluid}
            />
          </li>
          <li>
            <Img className="image"
              alt="Decorative Image"
              fluid={imgTransito.childImageSharp.fluid}
            />
          </li>
        </ul>
        <div className="contact">
          <a title="Whatsapp" href="https://wa.me/+573166259890" target="_blank" className="link">
            <div className="whatsapp">
                  <p className="PWhatsapp">
                    Le interesa solucionar problemas con comparendos de tránsito?
                    Escríbamos haciendo click aquí
                  </p>
                  <img className="iconContactW" src={logoWhatsapp} alt="Logo Whatsapp"></img>
            </div>
          </a>
          <a title="Facebook" href="https://facebook.com/Litigium-Corp-111712330186096/" target="_blank" className="link">
            <div className="facebook">
                <p className="Pfacebook">
                  La ultima actividad de nuestros asesores, noticias de actualidad
                  en Ibagué y recientes normas de derecho en el Tolima en nuestra
                  comunidad de Facebook
                </p>
                <img className="iconContactF" src={logoFace} alt="Logo Facebook"></img>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const {
    imgAdministrativo,
    imgCivil,
    imgConstitucional,
    imgFamilia,
    imgJudicial,
    imgLaboral,
    imgPenal,
    imgTransito,
    logoWhatsapp: {publicURL: logoWhatsappURL},
    logoFace: {publicURL: logoFaceURL},
    home: { frontmatter },
  } = data;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        imgAdministrativo={imgAdministrativo}
        imgCivil={imgCivil}
        imgConstitucional={imgConstitucional}
        imgFamilia={imgFamilia}
        imgJudicial={imgJudicial}
        imgLaboral={imgLaboral}
        imgPenal={imgPenal}
        imgTransito={imgTransito}
        logoWhatsapp={logoWhatsappURL}
        logoFace={logoFaceURL}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    home: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    img1: PropTypes.object,
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    home: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    logoWhatsapp: file(name: {eq: "whatsapp"}){
      publicURL
    }
    logoFace: file(name: {eq: "facebook"}) {
      absolutePath
      publicURL
    }
    imgAdministrativo: file(relativePath: { eq: "administrativo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgCivil: file(relativePath: { eq: "civil.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgConstitucional: file(relativePath: { eq: "constitucional.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgFamilia: file(relativePath: { eq: "familia.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgJudicial: file(relativePath: { eq: "judicial.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgLaboral: file(relativePath: { eq: "laboral.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgPenal: file(relativePath: { eq: "penal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgTransito: file(relativePath: { eq: "transito.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;